import React from 'react';

class Industries extends React.PureComponent {
  render() {
    return (
      <div>
        Industries
      </div>
    )
  }
}

export default Industries;
